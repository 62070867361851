@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');


a {
    color: #000!important;
    text-decoration: none!important;
}
body {
  font-family: 'Open Sans', sans-serif;
}

h1 {
  font-family: 'Montserrat', sans-serif!important;  
}

.body-wrapper {
  max-height: 100vh!important;
  max-width: 100vw!important;
  background-color: #fafafa;
  position: relative;
}

.nav-container {
  background-color: #fff;
  height: 100vh!important;
  width: 100vw!important;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.navbar-nav:last-child {
  box-shadow: none!important;
}

.page-container {
  overflow: hidden;
  height: 85%;
  width: 92%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.logo_home {
    position: absolute;
    z-index: 9;
    width: 9%;
    left: 4%;
    top: 6%;
}

.logo_other {
  position: absolute;
  z-index: 9;
  width: 9%;
  right: 4%;
  top: 6%;
}

nav {
    width: 50%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1%;
    margin: auto;
}

.navbar-nav {
  justify-content: center;
  flex-direction: row!important;
}

.router-link-exact-active {
  text-decoration: underline!important;
}

.nav-link:focus, .nav-link:hover {
  color: #000!important;
}

.slider_background_home {
  height: 85vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}

.slider_background_pages {
  height: 85vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.page-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.page-inner {
  width: 50%;
  position: relative;
}

.content-wrapper {
  max-width: 100%;
  padding: 0 40px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
}

.content-wrapper h1 {
  font-size: 1.5rem;
}

.content-wrapper a,
.content-wrapper p {
  font-size: 0.85rem;
  display: block;
}

.content-wrapper a {
  cursor: pointer;
}

.content-wrapper li {
  list-style: none;
}

.audio-btn {
  cursor: pointer;
  padding-left: 10px;
}

.slider .nextButton,
.slider .previousButton {
  display: none!important;
}

.slide {
  background-size: contain!important;
}
.large-nav {
  display: block;
}

.pan-container {
  padding-top: 30px;
  overflow: hidden;
}

.sliding-background {
  height: 170px;
  background-size: contain;
  width: 30300px;
  background-repeat: repeat-x;
  animation: pan 240s linear infinite;
}

@keyframes pan {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-10376px, 0, 0); /* The image width */
  }
}

@media screen and (min-width: 724px) and (max-width: 791px){
  .nav-container {
    height: 100%!important;
  }

  .page-container {
    height: 70%!important;
  }

  #other {
    overflow: scroll;
  }

  .page-inner {
    width: 50%;
  }

  .content-wrapper {
    margin: 0;
    position: relative; 
    top: 0;
    left: 0%;
    transform: none;
  }

  .large-nav {
    bottom: -8%;
    background-color: none;
    width: 100%;
  }

  .nav-item .nav-link.router-link-exact-active {
    border-left: none; 
    padding-left: 0px!important;
    }

  .slider_background_pages {
    background-size: contain;
    height: 62vh!important;
  }

  .slider_background_home {
    background-size: contain;
    background-position: center!important;
    height: 63vh!important;
  }

  .pan-container {
    padding-top: 0px;
  }
}

@media screen and (max-width: 723px) {
  .body-wrapper {
    max-height: 100%!important;
  }

  .pan-container {
    padding-top: 30px;
  }

  .nav-container {
    height: 100%!important;
  }

  .logo_other,
  .logo_home {
    width: 97px;
    left: 3%;
    top: 6%;
  }

  .large-nav {
    bottom: -4%;
    background-color: none;
    width: 100%;
  }

  .navbar-nav .nav-link {
    margin: 0 25px 0 0!important;
  }
  
  .logo-header {
    width: 25%;
  }


.modal-body {
  padding: 0 40px!important;
  margin: 0;
  position: absolute!important;
  top: 50%;
  left: 0;
  right: 0;
  transform: translate(0%, -50%);
  }

  .modal-body .nav-item {
    list-style: none;
    margin-bottom: 0.5rem;
    text-align: center;
  }

  .modal-body .nav-item a {
    color: #000;
    font-size: 2.5rem;
  }

  .nav-item .nav-link.router-link-exact-active {
    border-left: none;
    padding-left: 0!important;
  }

  .page-container {
    overflow: scroll;
  }

  #home {
    overflow: hidden!important;
    height: 75%!important;
  }

  .page-wrapper {
    flex-flow: column-reverse;
  }

  .page-inner {
    width: 100%;
  }

  .content-wrapper {
    padding: 80px 10px 0px 10px;
    margin: 0;
    position: relative; 
    top: 0;
    left: 0%;
    transform: none;
  }

  .slider_background_pages {
    background-size: contain;
    height: 50vh!important;
  }
  .slider_background_home {
    background-size: contain;
    background-position: center!important;
    height: 63vh!important;
  }
}




/* .carousel-item {
    -webkit-transition: 1.9s ease-in-out left!important;
    -moz-transition: 1.9s ease-in-out left!important;
    -o-transition: 1.9s ease-in-out left!important;
    transition: 1.9s ease-in-out left!important;
  } */